export const names: any = {
  A: {},
  B: {},
  C: {},
  D: {},
  E: {},
  F: {},
  G: {
    screens: [
      {
        names: [
          "GALLOWAY, LIZ",
          "GARDENER, WILLIAM",
          "GARY, DAVID",
          "GENNARO, HOLLY",
          "GFELLER, BRUCE",
          "GIBBS, ADDIE",
          "GOODS, BETTY",
          "GRASMERE, ROBERT",
        ],
      },
    ],
  },
  H: {},
  I: {},
  J: {},
  K: {},
  L: {},
  M: {
    screens: [
      {
        names: [
          "MAAS, STANLEY",
          "MABRY, ANN",
          "MABUS, FREDRICK",
          "MacADAM, M. J.",
          "MacALUSO, D. L.",
          "MacBETH, CHRISTINE",
          "MacCARTHY, M. L.",
          "MacConnel, F. R.",
          "MacDANIELS, HELEN",
          "MAN, M. R.",
        ],
      },
      {
        names: [
          "MARTINEZ, PEDRO",
          "McALEE, CHARLES",
          "McMURRY, GREGORY",
          "MEANS, BERNARDO",
          "MEEHAN, DANIEL",
          "MEEKS, DAVID",
          "MILLER, TERRY",
          "MOODY, MARGARET",
        ],
      },
    ],
  },
  N: {},
  O: {},
  P: {},
  Q: {},
  R: {},
  S: {},
  T: {},
  U: {},
  V: {},
  W: {},
  X: {},
  Y: {},
  Z: {},
} as const;
